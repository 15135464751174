export interface SwResponse extends Response {
  json: <T = unknown>() => Promise<T>
}

export interface ResponseExt<T = any> extends SwResponse {
  data: T
}

export interface AxiosPromiseCancelable<T = any> extends Promise<SwResponse> {
  arrayBuffer: () => Promise<ArrayBuffer>
  blob: () => Promise<Blob>
  formData: () => Promise<FormData>
  /**
    Get the response body as JSON.

    @example
    ```
    import ky from 'ky';

    const json = await ky(…).json();
    ```

    @example
    ```
    import ky from 'ky';

    interface Result {
        value: number;
    }

    const result = await ky(…).json<Result>();
    ```
    */
  json: <T = unknown>() => Promise<T>
  text: () => Promise<string>
  abort(): void
  then: Promise<ResponseExt<T>>['then']
}
export type Method = 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'GET' | 'PATCH'

export let isCancel: (error: any) => boolean
export let _callApi: <T = any>(
  url: string,
  method?: Method,
  body?: any | null,
  acc?: any,
  suppressShowError?: boolean,
  header?: Record<string, string>
) => AxiosPromiseCancelable<T>

export function setIsCancel(fn: typeof isCancel) {
  isCancel = fn
}

export function setCallApi(fn: typeof _callApi) {
  _callApi = fn
}

export function callApi<T = any>(
  url: string,
  method: Method = 'GET',
  body?: any | null,
  header?: Record<string, string>,
  suppressShowError: boolean = false
): AxiosPromiseCancelable<T> {
  return _callApi(url, method, body, header, suppressShowError)
}
